<template>
  <div class="dropDownMenuWrapper" :style="menuStyle">
    <button
      ref="menu"
      @click="openClose($event)"
      :aria-label="label"
      class="dropDownMenuButton"
      :class="disabled ? 'disabled' : ''"
    />
    <slot name="title" />
    <!-- title can either be an icon or a text -->
    <section v-if="isOpen" class="dropdownMenu" :style="`${style}${styleName}`">
      <p
        style="border-bottom: 0.5px solid rgb(135, 142, 153, 0.5)"
        v-if="hasDropdownTitleSlot"
        class="text-xs text-romanSilver font-semibold pt-3 mx-2"
      >
        <slot name="dropdownTitle" />
      </p>
      <slot />
    </section>
  </div>
</template>

<script>
export default {
  props: {
    right: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    bottomLeft: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: "-60"
    },
    styleName: {
      type: String,
      default: ""
    },
    innerMenu: {
      type: Boolean,
      default: false
    },
    topValue: {
      type: String,
      default: "60%"
    },

    margin: {
      type: String,
      default: "56"
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "button"
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openClose(event) {
      const closeListerner = e => {
        if (!this.disabled) {
          if (this.catchOutsideClick(e, this.$refs.menu)) {
            // eslint-disable-next-line no-unused-expressions
            window.removeEventListener("click", closeListerner);
            this.isOpen = false;
          }
        } else {
          this.isOpen = false;
        }
      };

      window.addEventListener("click", closeListerner);
      if (this.innerMenu) {
        event.stopPropagation();
      }
      this.isOpen = !this.isOpen;
    },
    catchOutsideClick(event, dropdown) {
      // When user clicks menu — do nothing
      if (dropdown === event.target) return false;

      // When user clicks outside of the menu — close the menu
      if (this.isOpen && dropdown !== event.target) return true;
      return false;
    }
  },
  computed: {
    hasDropdownTitleSlot() {
      return !!this.$slots.dropdownTitle;
    },

    style() {
      if (this.bottomLeft) {
        return "right: 0; top:60%;";
      }
      if (this.left) {
        return `right: 0; top:${this.top}%; margin-right:${this.margin}px;`;
      }
      if (this.right) {
        return `left: 0; top:${this.top}%; margin-left: ${this.margin}px;`;
      }
      return `left: 0; top:${this.topValue};`;
    },
    menuStyle() {
      if (this.hasBorder) {
        return "box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);";
      }
      return "box-shadow: none";
    }
  }
};
</script>

<style lang="scss" scoped>
.dropDownMenuWrapper {
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: inherit;
}
* {
  box-sizing: border-box;
}

.dropDownMenuButton {
  /* border: none;
  font-size: inherit;
  background: none;
  */
  outline: none;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  margin: 0;
  z-index: 9;
  line-height: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.dropDownMenuButton:focus {
  outline: none;
}

.dropdownMenu {
  position: absolute;
  border-radius: 5px;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
  animation: menu 0.3s ease forwards;
}
.disable {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

@keyframes menu {
  from {
    transform: translate3d(0, 30px, 0);
  }
  to {
    transform: translate3d(0, 20px, 0);
  }
}
</style>
