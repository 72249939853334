<template>
  <div class="radius" :class="disabled ? 'opacity-50' : ''">
    <Menu
      v-if="options"
      class="py-2 px-4 font-semibold text-center h-11 w-48 rounded buttonFocus"
      :style-name="styleName"
      :disabled="disabled"
      :style="` border:${border}; width: ${width}`"
    >
      <template v-slot:title>
        <div class="flex justify-between h-6">
          <slot />
          <icon icon-name="show_more" size="xsm" class="ml-2" />
        </div>
      </template>
      <div
        v-for="(item, index) in optionList"
        class="text-sm text-darkPurple hover:text-flame text-left cursor-pointer p-3"
        @click="$emit('click', item.name || item)"
        style="width: max-content"
        :key="index"
      >
        {{ item }}
      </div>
    </Menu>
    <button
      :style="
        `background:${background}; border:${border}; height:${height}; width:${width};`
      "
      v-else
      :disabled="disabled"
      class=" font-semibold rounded-md text-center radius buttonFocus "
      @click="disabled ? '' : $emit('click')"
    >
      <slot />
    </button>
  </div>
</template>

<script>
import Menu from "@/components/Menu";

export default {
  components: { Menu },
  name: "Button",
  props: {
    border: {
      type: String,
      default: "none"
    },
    background: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false
    },
    width: {
      type: String,
      default: "12rem"
    },
    styleName: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: "2.75rem"
    },
    options: {
      type: Boolean,
      default: false
    },
    cursor: {
      type: String,
      default: "cursor"
    },
    optionList: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  watch: {
    disabled(value) {
      this.disabled = value;
    }
  }
};
</script>

<style>
.buttonFocus:focus {
  outline: none;
}
.radius {
  border-radius: 5px;
}

button[disabled] {
  cursor: not-allowed !important;
}
</style>
